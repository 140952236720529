@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Seymour+One&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    color: black;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
}

.hero-text {
    font-family: 'Seymour One', sans-serif;
    font-style: normal;
}

.hero-bg {
    background-image: 'hero-cat.jpg';
    background-position: center;
    background-size: cover;
}
